.w-100-P {
  width: 100%;
}
.w-100 {
  width: 100px;
}
.w-300 {
  width: 300px !important;
}
.w-100-vw {
  width: 100vw;
}

.h-100-vh {
  height: 100vh;
}
.h-100-P {
  height: 100%;
}
.h-50 {
  height: 50px;
}

.h-100 {
  height: 100px;
}
.h-104 {
  height: 104px;
}
.colorChangingBG {
  background-image: url("../../images/colorchanginggif.gif");
  background-size: 100% 100%;
}
.bg-cb-blue {
  background-color: #33aafc !important;
}
.bg-white {
  background-color: white;
}
.cb-blue {
  color: #33aafc;
}
.white {
  color: white;
}
.pointer {
  cursor: pointer;
}

.large-button-border {
  border: solid;
  border-radius: 2px;
  border-color: grey;
}
.large-button-border:hover {
  border-color: #33aafc;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight:700;
}
.fs-20 {
  font-size: 20px;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.fs-25 {
  font-size: 25px;
}
.fs-28 {
  font-size: 28px;
}
.fs-30 {
    font-size: 30px;
}
.m-right-15 {
  margin-right: 15px;
}
.m-right-30 {
  margin-right: 30px;
}
.m-left-24 {
  margin-left: 24px;
}
.m-top-24 {
  margin-top: 24px;
}
.m-btm-25 {
    margin-bottom: 25px;
}
.m-btm-50 {
  margin-bottom: 50px;
}
.m-top-15 {
  margin-top: 15px;
}
.m-top-25 {
  margin-top: 25px;
}
.m-left-15 {
  margin-left: 15px;
}
.p-25 {
  padding: 25px;
}
.p-top-15 {
  padding-top: 15px;
}
.p-btm-35 {
  padding-bottom: 35px;
}
.p-left-15 {
  padding-left: 15px;
}
.p-right-15 {
  padding-right: 15px;
}
.p-btm-15 {
  padding-bottom: 15px;
}
.p-16 {
  padding: 16px;
}
.p-20 {
  padding: 20px;
}
.py-30 {
  padding: 30px 0;
}
.rem-pad-width {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.align-center {
  text-align: center !important;
  align-content: center;
}
.flex-row-wrap {
  display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch;
}
.flex-col-center {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: stretch;
}
.flex-col-left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}
