
.mergeBottomList {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: baseline;
    align-content: stretch;
}

.mergeToplist {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: stretch;
	align-content: stretch;
}

.centerCenterChildren {
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}

.pointer {
	cursor: pointer;
}

.clickableCardBox {
	box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
	padding: 10px;
}

.clickableCardBox:hover {
	box-shadow: rgba(0, 0, 255, 0.4) 0px 5px 15px;
}

.clickableDescription {
	cursor: pointer;
	color: blue;
}
.clickableDescription:hover {
	text-decoration: underline;
}

.nonClickableDescription {
	cursor:not-allowed;
	color: grey;
}

.removeFromList {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	align-content: stretch;
	margin: 10px;
}

.centerFlex {
	margin: 15px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
}