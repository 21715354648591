.cb-phone-input-2 {
  display: flex !important;
}

.cb-phone-input-2 .ant-select-arrow > *:not(:last-child) {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}

.cb-phone-input-2 .ant-select-arrow .ant-form-item-feedback-icon {
  display: none;
}

